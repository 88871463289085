<template>
  <div>
    <div class="agent_search">
    <label class="lab" for="searchname">商品名称</label>
    <el-input clearable name="searchname" placeholder="请输入商品名称" class="goods_search" v-model="goodsname"></el-input>
      <el-button type="primary" size="small" @click="searchs" style="margin-left:10px">搜索</el-button>
    </div>
          <ul class="tit">
            <li v-for="(item,index) in options" :key="index" v-show="item.children">
              <span>{{item.brand_name}}</span>
              <ul style="padding:20px 0  0 80px;">
                <li class="content" v-for="s_item in item.children" @click="to_list(s_item.brand_name,item.brand_name,s_item.id)" :key="s_item.id">{{s_item.brand_name}}  </li>
              </ul>
            </li>
          </ul>
  </div>
</template>

<script>
export default {
  inject:['chanlist'],
  name: "Goods_order",
  data(){
    return{
      goodsname:"",
      options:""
    }
  },
  mounted() {
    this.chanlist('1-2','1')
      this.search()
  },

  methods:{
    to_list(a,b,id){
      this.$router.push({name:'Breadcrumb' , params:{ a,b,id}})
    },
    searchs(){
      this.$router.push({name:'Goods_list',params:{g_name:this.goodsname,ref:2}})
    },
    search(){
      this.$axios.post("/supplier/good/list/brand",{type:2}).then((res)=>{
        if (res.data.code==20000) {
        this.options=res.data.data
        }
  })
    },
  }
};
</script>

<style lang="less" scoped>
.agent_search{width:99%;height: 60px;border: 1px solid #ccc;margin-top: 10px;float: left;text-align: left;margin-left:10px ;
.lab{font-size: 16px;margin:15px 10px 10px 20px;display: inline-block}
.goods_search{width: 220px;height: 25px;border-color: initial;margin-left: 3px}}
ul li {list-style: none;}
.tit{width: 100%;text-align: left;
  >li{margin-top: 20px;
    span{font-size:20px;font-family:Microsoft YaHei;padding-top: 10px;padding-left:40px;}}
}
.content{display: inline-block;font-size: 16px;cursor: pointer;width: 22%;}
</style>